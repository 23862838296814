import '../Main.css';

export default function Announcement() {
	return (
		<div
			id='Announcement'
			className='section'
		>
			<h1 className='headingTitle'>&nbsp; We're getting married! &nbsp;</h1>
			<h4>May 4th, 2024</h4>
			<h4>Please arrive by 4:00pm</h4>
			<h4>at</h4>
			<h4>
				Holy Family Episcipal Church
				<br />
				{/* <br /> */}
			</h4>
			<h5>
				11445 Fishers Pointe Boulevard
				<br />
				Fishers, IN 46038
			</h5>
			<p>
				<a href='https://www.holyfamilyfishers.org/'>Website</a>
			</p>
			<br />
			<br />
			<h5>Please RSVP by April 28th, 2024.</h5>
		</div>
	);
}
