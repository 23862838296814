import { FAQData } from '../Data';
import '../Main.css';

export default function FAQs() {
	return (
		<div
			id='FAQs'
			className='faq'
		>
			<h1 className='headingTitle'>&nbsp; Questions & Answers &nbsp;</h1>
			<div className='parent'>
				{FAQData.map((item, index) => (
					<div key={index}>
						<div className='question item'>
							<h5><strong>Question: </strong></h5>
							<h5>{item.question}</h5>
						</div>
						<div className='answer item'>
							<h5><strong>Answer: </strong></h5>
							<h5>{item.answer}</h5>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
