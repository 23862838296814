import { RegistriesData } from './Data';

export default function Registries() {
	return (
		<div
			id='Registries'
			className='section'
		>
			<h1 className='headingTitle'>&nbsp; Our Registries &nbsp;</h1>
			{RegistriesData.map((item, index) => (
				<a
					href={item.url}
					key={index}
					className='registries'
				>
					<img
						className='logo'
						src={require(`../Images/${item.image}`)}
						alt={item.name}
					/>
					<h2 className='registryName'>{item.name}</h2>
				</a>
			))}
		</div>
	);
}
