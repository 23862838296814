import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import Main from './Components/Main';
import Navbar from './Components/Navbar';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Main />,
	}
]);

function App() {
	return (
		<>
			<Navbar />
			<div
				// data-bs-spy='scroll'
				// data-bs-target='#navbar-example2'
				// data-bs-root-margin='0px 0px -40%'
				// data-bs-smooth-scroll='true'
				// className='scrollspy-example p-3 rounded-2'
				// tabindex='0'
			>
				<RouterProvider router={router} />
			</div>
		</>
	);
}

export default App;
