import { NearHotelData, FarHotelData } from '../Data';
import '../Main.css';

export default function FAQs() {
	return (
		<div
			id='Travel'
			className='travel'
		>
			<h1 className='headingTitle'>&nbsp; Getting & Staying Here &nbsp;</h1>
			<div className='parent'>
				<h3>Hotels close by...</h3>
				{NearHotelData.map((item, index) => (
					<div key={index}>
					<div className=''>
						<h4>
							<a href={item.url}>{item.name}</a>
						</h4>
					</div>
					<div className=''>
						<h5>
							<a href={item.url}>{item.address}</a>
						</h5>
					</div>
				</div>
				))}
			</div>
			<br />
			<br />
			<br />
			<div className='parent'>
				<h3>Hotels a little further away...</h3>
				{FarHotelData.map((item, index) => (
					<div key={index}>
						<div className=''>
							<h4>
								<a href={item.url}>{item.name}</a>
							</h4>
						</div>
						<div className=''>
							<h5>
								<a href={item.url}>{item.address}</a>
							</h5>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
