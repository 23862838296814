import Hero from '../Images/001.webp';
import './Main.css';
import Announcement from './Main/Announcement.js';
import FAQs from './Main/FAQs.js';
// import Itinerary from './Main/Itinerary.js';
import Travel from './Main/Travel.js';
import Registries from './Registries.js';
import Footer from './Footer.js';

function Main() {
	return (
		<div className='main'>
			<div className='Hero'>
				<img
					className='banner'
					src={Hero}
					alt='Welcome'
				/>
			</div>

			<Announcement />
			{/* <Itinerary /> */}
			<FAQs />
			<Travel />
			<Registries />
			<Footer />
		</div>
	);
}

export default Main;
