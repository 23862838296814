export const NavbarData = [
	{
		title: 'Home',
		url: '/',
		className: 'nav-link',
		icon: 'fa-solid fa-house-user',
	},
	{
		title: 'FAQs',
		url: '#FAQs',
		className: 'nav-link',
		icon: 'fa-solid fa-house-user',
	},
	{
		title: 'Travel',
		url: '#Travel',
		className: 'nav-link',
		icon: 'fa-solid fa-house-user',
	},
	{
		title: 'Registries',
		url: '#Registries',
		className: 'nav-link',
		icon: 'fa-solid fa-house-user',
	},
];

export const ItineraryData = [
	{ time: '6:30 pm', event: "I do's" },
	{ time: '7:15 pm', event: 'Let the games begin' },
	{ time: '7:30 pm', event: 'Dinner' },
	{ time: '8:30 pm', event: '(Cheese) Cake cutting' },
	{ time: '8:35 pm', event: 'First Dance' },
	{ time: '8:40 pm', event: 'Dancing, games & smores' },
	{ time: '11:00 pm', event: 'Send off' },
];

export const PartyData = [
	{ image: '002.webp', name: 'Erika', description: 'Bad bitch' },
	{ image: '002.webp', name: 'Erika', description: 'Bad bitch' },
	{ image: '002.webp', name: 'Erika', description: 'Bad bitch' },
];

export const FAQData = [
	{
		question: 'What is the dress code?',
		answer:
			'Fancy casual, please consider spring time oranges and pinks for the ladies and dark navy for the gentlemen.',
	},
	{
		question: 'Will the wedding be indoors or outdoors?',
		answer:
			'As long as weather permits, the ceremony will be outside. We will then move indoors for the reception and all other festivities.',
	},
	{
		question: 'How do I RSVP?',
		answer:
			`Once you've received your invitation, scan the QR code on the back and answer the questions, even if you are not planning to attend. Please RSVP by April 28th, 2024.`,
	},
	{
		question: 'Can I bring my children?',
		answer:
			'We ask that you make a date night of our celebration, and only bring guests addressed on the invitation that are 18 years of age or older.',
	},
	{
		question: 'Where do you recommend I stay?',
		answer: `Recommendations are in our 'Travel' section in the navbar above,`,
	},
	{
		question: 'Are you registered somewhere for gifts? Where should we ship our gift to you?',
		answer:
			'Our registry links are in the navbar above. If you buy online, the registry will send it to us. If you want the experience of shopping in-store, please email us for our mailing address or bring your gift to the wedding!',
	},
	{
		question: 'I have a question, what is the best way to contact you?',
		answer: 'Please email us at us@kelsiandedwin.com',
	},
];

export const RegistriesData = [
	{
		name: 'Amazon',
		url: 'https://www.amazon.com/wedding/share/kelsiandedwin',
		image: 'Amazon.webp',
	},
	{
		name: 'Target',
		url: 'https://www.target.com/gift-registry/gift/kelsi-and-edwin',
		image: 'Target.webp',
	},
	{
		name: 'IKEA',
		url: 'https://www.ikea.com/us/en/gift-registry/guest/?id=32d902a3-9922-465c-87c3-6bace50c6c78',
		image: 'IKEA.webp',
	},
];

export const NearHotelData = [
	{
		name: 'Hampton Inn & Suites',
		address: '11575 Commercial Dr, Fishers, IN 46038',
		url: 'https://www.hilton.com/en/hotels/indfshx-hampton-suites-indianapolis-fishers/',
	},
	{
		name: 'Courtyard by Marriott',
		address: '11550 Whistle Dr, Fishers, IN 46037',
		url: 'https://www.marriott.com/en-us/hotels/indcf-courtyard-indianapolis-fishers/overview/',
	},
	{
		name: 'Hyatt House',
		address: '11455 IKEA Wy, Fishers, IN 46037',
		url: 'https://www.hyatt.com/en-US/hotel/indiana/hyatt-house-indianapolis-fishers/indxf',
	},
	{
		name: 'Fairfield Inn & Suites by Marriott',
		address: '10495 Crosspoint Blvd, Indianapolis, IN 46256',
		url: 'https://www.marriott.com/en-us/hotels/indif-fairfield-inn-and-suites-indianapolis-fishers/overview/',
	},
];

export const FarHotelData = [
	{
		name: 'Red Roof Inn Indianapolis',
		address: '8110 Shadeland Ave, Indianapolis, IN 46250',
		url: 'https://www.redroof.com/property/in/indianapolis/RRI827',
	},
	{
		name: 'Candlewood Suites',
		address: '8111 Bash St, Indianapolis, IN 46250',
		url: 'https://www.ihg.com/candlewood/hotels/us/en/indianapolis/indbs/hoteldetail',
	},
	{
		name: 'Days Inn by Wyndham',
		address: '8300 Craig St, Indianapolis, IN 46250',
		url: 'https://www.wyndhamhotels.com/days-inn/indianapolis-indiana/days-inn-indianapolis-northeast/overview',
	},
	{
		name: 'WoodSpring Suites',
		address: '8301 Bash St, Indianapolis, IN 46250',
		url: 'https://www.woodspring.com/extended-stay-hotels/locations/indiana/indianapolis/woodSpring-suites-indianapolis-castleton',
	},
];
