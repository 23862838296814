import React from 'react';
import { NavbarData } from './Data';

function Navbar() {
	return (
		<>
			<nav className='navbar navbar-expand-md fixed-top navbar-light'>
				<div className='container-xxl'>
					<a
						className='navbar-brand'
						href='/'
					>
						Kelsi & Edwin's Wedding
					</a>

					<button
						className='navbar-toggler'
						type='button'
						data-bs-toggle='collapse'
						data-bs-target='#main-nav'
						aria-controls='main-nav'
						aria-expanded='false'
						aria-label='Toggle navigation'
					>
						<span className='navbar-toggler-icon'></span>
					</button>

					<div
						className='collapse navbar-collapse justify-content-end align-center'
						id='main-nav'
					>
						<ul className='navbar-nav'>
							{NavbarData.map((item, index) => (
								<li
									className='nav-item'
									key={index}
								>
									<a
										href={item.url}
										className={item.className}
									>
										<i className={item.icon}></i>
										{item.title}
									</a>
								</li>
							))}
						</ul>
					</div>
				</div>
			</nav>
		</>
	);
}

export default Navbar;
